import React from 'react';
import { func, object, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as PlayIcon } from 'assets/icons/ic_video_banner_play.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/ic_video_banner_pause.svg';
import { ReactComponent as SoundOnIcon } from 'assets/icons/ic_video_banner_sound_on.svg';
import { ReactComponent as SoundOffIcon } from 'assets/icons/ic_video_banner_sound_off.svg';
import { ReactComponent as NextIcon } from 'assets/icons/ic_video_banner_next.svg';
import { ReactComponent as PreviousIcon } from 'assets/icons/ic_video_banner_previous.svg';

// Import context
import usePlayerContext from 'components/elements/molecules/video_banners/PlayerProvider/usePlayerLiveBannerContext';

// Import styles
import { Wrapper, FlexWrapper } from './styles';

// Import components
import DetailsButton from './DetailsButton';
import { Button, Loader } from 'components/elements';
import PlayerError from 'components/elements/molecules/video_banners/PlayerError';

const LiveBannerPlayerContent = ({
	nextProduct,
	previousProduct,
	currentProduct,
	name,
	hasNextProduct,
	hasPreviousProduct,
	showArrows = true
}) => {
	const { t } = useTranslation();

	const {
		buffering,
		isReady,
		isPaused,
		isMuted,
		togglePlay,
		toggleSound,
		isError,
		errorMessage
	} = usePlayerContext();

	const isLoading = buffering && !isReady;

	const playButtonAriaText = isPaused
		? t('aria_video_banner_play')
		: t('aria_video_banner_pause');

	const soundButtonAriaText = isMuted
		? t('aria_video_banner_unmuted')
		: t('aria_video_banner_muted');

	const urlPc = currentProduct?.context?.url_pc;

	if (isLoading) {
		return <Loader zIndex={900} position="absolute" background={false} />;
	}

	return (
		<>
			<Wrapper>
				<DetailsButton urlPc={urlPc} />

				<FlexWrapper>
					<Button
						onClick={togglePlay}
						modifiers="videoBanner"
						aria-label={playButtonAriaText}
					>
						{isPaused ? (
							<PlayIcon aria-hidden role="img" />
						) : (
							<PauseIcon aria-hidden role="img" />
						)}
					</Button>
					{showArrows ? (
						<>
							<Button
								onClick={previousProduct}
								modifiers="videoBanner"
								aria-label={`${t('aria_video_banner_previous')}: ${name}`}
								disabled={!hasPreviousProduct}
							>
								<PreviousIcon aria-hidden role="img" />
							</Button>
							<Button
								onClick={nextProduct}
								modifiers="videoBanner"
								aria-label={`${t('aria_video_banner_next')}: ${name}`}
								disabled={!hasNextProduct}
							>
								<NextIcon aria-hidden />
							</Button>
						</>
					) : null}
					<Button
						onClick={toggleSound}
						modifiers="videoBanner"
						aria-label={soundButtonAriaText}
					>
						{isMuted ? (
							<SoundOffIcon aria-hidden role="img" />
						) : (
							<SoundOnIcon aria-hidden role="img" />
						)}
					</Button>
				</FlexWrapper>
			</Wrapper>

			{isError && <PlayerError message={errorMessage} />}
		</>
	);
};

LiveBannerPlayerContent.propTypes = {
	currentProduct: object.isRequired,
	nextProduct: func.isRequired,
	previousProduct: func.isRequired,
	name: string.isRequired,
	hasNextProduct: bool,
	hasPreviousProduct: bool,
	showArrows: bool
};

export default LiveBannerPlayerContent;
