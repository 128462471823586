import React, { useCallback } from 'react';
import { object, string, func, shape, bool } from 'prop-types';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// Import styles components
import { Wrapper, FormError, InputWrapper, Label } from '../styles';
import { DatePickerStyled } from './styles';

// Import helpers
import { getUserAgentInfo } from 'helpers';

// Import variables
import { DATE_FORMAT } from 'helpers/variables';

const { REGISTER_DATEPICKER_FORMAT } = DATE_FORMAT;

const RegisterDateField = ({
	field,
	form,
	placeholder,
	setFieldValue,
	setFieldTouched,
	required
}) => {
	const { isMobile } = getUserAgentInfo();
	const { t } = useTranslation();

	const { language } = i18next;
	const { touched, errors } = form;

	const { name, value } = field;
	const isErrorVisible = touched[name] && errors[name];
	const errorText = errors[name] || '';

	const handleChange = useCallback(
		(date) => {
			setFieldValue(name, date);
			setFieldTouched(name);
		},
		// eslint-disable-next-line
		[name]
	);

	// Can not select days after today and today
	return (
		<Wrapper>
			<InputWrapper isErrorVisible={isErrorVisible}>
				<DatePickerStyled
					name={name}
					id={name}
					onChange={handleChange}
					value={value}
					locale={language}
					format={REGISTER_DATEPICKER_FORMAT}
					maxDate={new Date()}
					dayPlaceholder={t('common_register_form_day_placeholder')}
					monthPlaceholder={t('common_register_form_month_placeholder')}
					yearPlaceholder={t('common_register_form_year_placeholder')}
					className={`${isErrorVisible ? 'errors' : ''}`}
					clearIcon={null}
					isMobile={isMobile}
					//aria props
					calendarAriaLabel={t('aria_register_form_calendar_button')}
					dayAriaLabel={t('common_day')}
					monthAriaLabel={t('common_month')}
					yearAriaLabel={t('common_year')}
					nativeInputAriaLabel={t('common_date')}
					isErrorVisible={isErrorVisible}
				/>
				<Label
					className={value && 'filled'}
					htmlFor={name}
					isErrorVisible={isErrorVisible}
				>
					{placeholder} {required && '*'}
				</Label>
			</InputWrapper>
			<FormError
				isErrorVisible={isErrorVisible}
				role="alert"
				aria-atomic="true"
				id={`${name}-alert`}
			>
				{errorText}
			</FormError>
		</Wrapper>
	);
};

RegisterDateField.defaultProps = {
	type: 'date',
	autoComplete: 'true',
	required: false
};

RegisterDateField.propTypes = {
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	setFieldValue: func.isRequired,
	setFieldTouched: func.isRequired,
	autoComplete: string,
	type: string,
	required: bool
};

export default RegisterDateField;
