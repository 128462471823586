import { useContext } from 'react';
import { PlayerContext } from './PlayerProviderLiveBanner';

const usePlayerLiveBannerContext = () => {
	const context = useContext(PlayerContext);

	return context;
};

export default usePlayerLiveBannerContext;
