import { DOCUMENTS_ALIASES } from 'helpers/variables.js';

const {
	ABOUT_US,
	REGULATIONS,
	PRIVACY_POLICY,
	COOKIES_POLICY,
	FAQ,
	DCB_PAYMENT_TERMS,
	RIGHT_OF_WITHDRAWAL,
	LEGAL_NOTICE
} = DOCUMENTS_ALIASES;

export const DOCUMENTS_PATHS = {
	DOCUMENT: '/document/:alias',
	ABOUT_US: `/document/${ABOUT_US}`,
	REGULATIONS: `/document/${REGULATIONS}`,
	PRIVACY_POLICY: `/document/${PRIVACY_POLICY}`,
	COOKIES_POLICY: `/document/${COOKIES_POLICY}`,
	FAQ: `/document/${FAQ}`,
	DCB_PAYMENT: `/document/${DCB_PAYMENT_TERMS}`,
	RIGHT_OF_WITHDRAWAL: `/document/${RIGHT_OF_WITHDRAWAL}`,
	LEGAL_NOTICE: `/document/${LEGAL_NOTICE}`
};
