// Import translation
import i18n from 'i18next';

// Localstorage
export const LOCALSTORAGE_USER = '_v_w';
export const LOCALSTORAGE_PLAYER = '_sh_p_';
export const LOCALSTORAGE_TRACKING = '_sh_p_t';
export const LOCAL_STORAGE_LANGUAGE = '_lng_';
export const LOCAL_STORAGE_PLAYER_VOLUME = '_p_v';

// Sessionstorage§
export const SESSIONSTORAGE_OS_SUPPORT = '_os_sup';
export const SESSIONSTORAGE_INSTITUTION = '_in';

// Cookie
export const COOKIE_VIDEO_SESSION = '_sh_p_v_s';
export const COOKIE_EXPIRES = 3650;
export const COOKIE_UID = '_uid';
export const COOKIE_NEWSLETTER = '_nl';
export const COOKIES_ACCEPTED = '_c_a';
export const COOKIES_AFFILIATE_CLIENT = '_aff_c';
export const COOKIES_AFFILIATE_CLIENT_EXPIRES = 30;

// Refresh programs interval
export const REFRESH_PROGRAMS_INTERVAL = 300000;

// Update live program interval
export const UPDATE_LIVE_PROGRAM_INTERVAL = 60000;

// Browser wake up interval
export const BROWSER_WAKE_UP_INTERVAL = 6000;

// Refetch transaction status timeout
export const RECHECK_TRANSACTION_INTERVAL = 5000;

// Successfull account removal timeout
export const SUCCESSFULL_ACCOUNT_REMOVAL_TIMEOUT = 5000;

// Login
export const AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED';
export const DEVICES_LIMIT_EXCEEDED =
	'SUBSCRIBER_ASSIGNED_DEVICES_LIMIT_EXCEEDED';
export const DEVICE_NOT_EXISTS = 'DEVICE_NOT_EXISTS';

export const NO_PROGRAM = 'NO_PROGRAM';

export const CHANNEL_OFFSET_LIMIT = 'offset=0&limit=300';

export const HEADER_HEIGHT_DESKTOP = 92;

export const HEADER_HEIGHT_MOBILE = 70;

// SectionsGroup names
export const PAGES_LABELS = {
	MAIN_WEB: 'MAIN_WEB',
	VOD_WEB: 'VOD_WEB',
	SERIES_WEB: 'SERIES_WEB',
	KIDS_WEB: 'KIDS_WEB',
	START_WEB: 'START_WEB',
	INSTITUTION: 'PRODUCT_GROUP_WEB',
	TEATR_WEB: 'TEATR_WEB',
	FILM_WEB: 'FILM_WEB',
	WYSTAWY_WEB: 'WYSTAWY_WEB',
	MUZYKA_WEB: 'MUZYKA_WEB'
};

// Home
export const SECTIONS_TYPES = {
	BANNER: 'banner',
	VIRTUAL: 'virtual',
	CAROUSEL: 'carousel',
	PROMOTED: 'promoted',
	NORMAL: 'normal',
	NORMAL_COVER: 'normal_cover',
	LARGE: 'large',
	SCHEDULE: 'schedule',
	HIGH_BANNER: 'high_banner',
	CHANNEL_EPG: 'channel_epg',
	VIDEO_BANNER: 'video_banner',
	EXTRA_LOW_BANNER: 'extra_low_banner'
};

export const SECTIONS_LABEL_NAMES = {
	THEATER: 'theater',
	MOVIES: 'movies',
	EXHIBITIONS: 'exhibitions',
	MUSIC: 'music',
	KIDS: 'kids'
};

export const VIRTUAL_SECTIONS_TYPES = {
	FAVOURITE_CHANNELS: 'favourite_channels',
	FAVOURITE_MOVIES: 'favourite_movies',
	FAVOURITE_SERIES: 'favourite_series'
};

// VOD
export const VOD_SECTIONS_TYPES = {
	WEB_MOVIES_CONTINUE_WATCHING: 'web_filmy_kontynuujoglądanie'
};

export const VOD_PARAMS = {
	genre: 'genre',
	type: 'type',
	order: 'order',
	orderDir: 'orderDir'
};

export const VOD_PROMOTED_TYPES = {
	eu: 'eu',
	pl_eu: 'pl_eu'
};

// EPG
export const EPG_SELECTORS = {
	EPG_CLASS: 'epg'
};

// Player Types
export const PLAYER_TYPES = {
	CATCHUP: 'CATCHUP',
	LIVE: 'LIVE'
};

export const PLAYER_VIDEO_TYPES = {
	VOD: 'vod',
	LIVE_EVENT: 'live_event'
};

export const PLAYER_VOD_LIVE_DELAY = 40;

// Minimal time to display continue video popup (begin and end of the video)
export const PLAYER_CONTINUE_VIDEO_INERTIA_TIME = 4;

export const WATERMARK_URL = `${process.env.REACT_APP_API_URL}/assets/model/watermark/logo`;

// SearchButton
export const SEARCH_TYPES = {
	program: 'Program TV',
	channel: 'Kanał',
	vod: 'Film',
	series: 'Serial',
	season: 'Sezon',
	episode: 'Odcinek',
	packet: 'Pakiet',
	provision: 'Pakiet',
	collection: 'Kolekcja',
	main: 'Pakiet'
};

// Program details
export const PROGRAM_RECOMMENDATIONS = {
	MAX_RESULTS: 8
};

// Skip intro
export const INTRO = 'intro';

// Player selectros
export const PLAYER_SELECTORS = {
	PLAYER: 'player',
	DETAILS: 'player_details',
	VOD: 'player_vod',
	ATDS_PLAYER_CONTAINER_CLASS: 'atdsplayer-container',
	ATDS_PLAYER_HIDE_CURSOR_CLASS: 'hide-cursor',
	SHAKA_CONTROLS_CONTAINER_CLASS: 'shaka-controls-container'
};

export const PRODUCT_TYPES = ['program', 'vod', 'episode'];

export const VOD_PERMITTED_SECTION_LABELS = [
	'virtual',
	'carousel',
	'normal',
	'promoted',
	'banner'
];

// url platforms
export const PLATFORM_BROWSER = 'platform=BROWSER';

// core paths as stings
export const ROUTES_CORE_PATHS = {
	HOME: 'home-page',
	WATCH: 'watch',
	RECORDINGS: 'recordings',
	PACKETS: 'packages',
	PAYMENTS: 'payment-history',
	FAVOURITES: 'favorites',
	REGULATION: 'terms-and-conditions',
	CHANNELS: 'channels',
	PROGRAM_DETAILS: 'program-details',
	SEARCH: 'search',
	REGISTER: 'registration',
	LOGIN: 'login',
	TRANSACTION: 'transaction',
	USER: 'user',
	PASSWORD_RESET: 'password-reset'
};

// OS NOT SUPPORTED
export const OS_NOT_SUPPORTED = 'OS_NOT_SUPPORTED';

// Product image types
export const PRODUCT_IMAGES_TYPES = {
	COVER: 'cover',
	POSTER: 'poster',
	LOGO: 'logo',
	COVER_LARGE: 'cover_large',
	TITLE: 'title'
};

// query order translation to filter category items
export const QUERY_ORDER = {
	created_at: 'created_at',
	data_dodania: 'created_at',
	year: 'year',
	rok_produkcji: 'year',
	title_org: 'tytul_oryginalny',
	tytul_oryginalny: 'title_org',
	title: 'title',
	tytul: 'title',
	duration: 'duration',
	dlugosc: 'duration'
};

// query order dir translation to filter category items
export const QUERY_ORDER_DIR = {
	asc: 'asc',
	rosnaco: 'asc',
	desc: 'desc',
	malejaco: 'desc'
};

// query types translation to filter category items
export const QUERY_TYPES = {
	all: 'all',
	vod: 'vod',
	audio: 'audio',
	article: 'artykul',
	artykul: 'article',
	live_event: 'live',
	live: 'live_event'
};

// query subtypes translation to filter category items
export const QUERY_SUBTYPES = {
	vod: 'vod',
	series: 'serial',
	seriale: 'series'
};

// HOME PAGE LABEL
export const HOME_LABEL = 'MAIN_WEB';

// react app projects
export const APP_PROJECT_TYPES = {
	TV_SMART: 'TV_SMART',
	TVO: 'TVO',
	TV_ONLINE: 'TV_ONLINE'
};

// request url system types
export const SYSTEM_TYPES = {
	OTT: 'ott',
	TVONLINE: 'tvonline'
};

// base links to apps
export const BASE_APPS_URLS = {
	ANDROID_BASE_URL: 'https://play.google.com/store/apps/details?id=',
	APPLE_BASE_URL: 'https://itunes.apple.com/pl/app/'
};

// android apps ids
export const ANDROID_APPS_IDS = {
	ANDROID_TV_SMART_ID: 'pl.vectra.ott.android',
	ANDROID_TV_ONLINE_ID: 'pl.vectra.tv'
};

// apple apps ids
export const APPLE_APPS_IDS = {
	APPLE_TV_SMART_ID: '/vectra-tvonline/id1531305371',
	APPLE_TV_ONLINE_ID: 'vectra-tvonline/id1205677433'
};

// APP external links
export const EXTERNAL_LINKS = () => ({
	CKF_WEBSITE: '',
	VIMEO: '',
	FACEBOOK: i18n.t('common_app_facebook_link'),
	YOUTUBE: i18n.t('common_app_youtube_link'),
	INSTAGRAM: i18n.t('common_app_instagram_link'),
	BLUEONLINE: i18n.t('common_app_blueonline_link'),
	APP_STORE: i18n.t('common_app_appstore_link'),
	GOOGLE_PLAY: i18n.t('common_app_googleplay_link')
});

// meta tags login page links
export const META_LOGIN_LINKS = {
	TV_ONLINE_LOGIN: 'https://tvonline.vectra.pl/logowanie',
	TV_SMART_LOGIN: 'https://tvsmart.vectra.pl/logowanie'
};

//-------------- MOVIE PREVIEW CONSTANTS ---------------
// preview stripe url
export const STRIPES_URL =
	'https://r.dcs.redcdn.pl/file/o2/Vectra/cdn/public/assets/prod/vod';
// numbers of frame in single stripe
export const FRAMES_PER_STRIPE = 32;
// time for single fame
export const TIME_PER_FRAME = 30;

// tracking interval time
export const TRACKING_INTERVAL_TIME = 30000;

// tracking events
export const TRACKING_EVENTS = {
	PLAYING: 'PLAYING',
	BUFFERING: 'BUFFERING',
	PAUSED: 'PAUSED',
	SEEKING: 'SEEKING',
	TRACK_CHANGED: 'TRACK_CHANGED',
	COMPLETE: 'COMPLETE',
	STOPPED: 'STOPPED',
	CLOSED: 'CLOSED',
	ERROR: 'ERROR'
};

// purchase query string values - from blue media url
export const PURCHASE_QUERY_STRING_PARAMS = {
	ORDER_ID: 'OrderID',
	STATUS: 'Status'
};

// status types (mainly used for tracking request status in Redux)
export const STATUS_TYPES = {
	idle: 'idle',
	loading: 'loading',
	success: 'success',
	error: 'error',
	pending: 'pending'
};

// status types for transacion status (response from bluemedia)
export const TRANSACTION_STATUS_TYPES = {
	init: 'init',
	pending: 'pending',
	succeed: 'succeed',
	failed: 'failed' // - also when user cancels transaction
};

export const STATUS_FROM_URL_FAILED = '-1';

export const CONFIRMATION_STATUS_WAITING = 'waiting';

// names of login tabs
export const LOGIN_TABS_NAMES = { nextGen: 'nextGen', vectra: 'vectra' };

// login system types
export const LOGIN_SYSTEM_TYPES = { pureott: 'pureott', vectra: 'vectra' };

// portal languages
export const LANGUAGES_TYPES = {
	EN: 'en',
	PL: 'pl',
	AR: 'ar',
	UK: 'uk',
	RU: 'ru',
	UA: 'ua'
};

// CSS Positions
export const CSS_POSITIONS = {
	ABSOLUTE: 'absolute',
	STATIC: 'static',
	RELATIVE: 'relative',
	FIXED: 'fixed',
	STICKY: 'sticky'
};

export const VECTRA_LOGIN_BACKGROUND =
	'https://r.dcs.redcdn.pl/scale/o2/Vectra/cdn/images/static/start.jpg?srcmode=4&srcw=5114&srch=2806&dstw=3061&dsth=1680&type=1&quality=80';

export const DEFAULT_PLAYER = process.env.REACT_APP_DEFAULT_PLAYER;

export const THEO_PLAYER = 'theoplayer';

export const THEO_LIBRARY_URL = `https://cdn.myth.theoplayer.com/${process.env.REACT_APP_THEO_LIBRARY_KEY}`;

// POSTAL CODES
export const POSTAL_CODES_REGEX = {
	POLAND: /^([0-9]{2})(-[0-9]{3})?$/i
};

// INVOICE
export const INVOICE_TYPES = {
	BUSINESS: 'business',
	INDIVIDUAL: 'individual'
};

//CUSTOM SLIDER ARROWS INVOICE_TYPES
export const SLIDER_ARROW_TYPES = {
	PREV: 'prev',
	NEXT: 'next'
};

export const DEFAULT_SLIDER_CONFIG = {
	resolutionWidth: 4.19,
	resolutionHeight: 2.36,
	spaceBetweenSlides: 19,
	responsive: {
		small: 1.3,
		medium: 3.5,
		large: 4.3
	}
};

export const LARGE_SLIDER_CONFIG = {
	resolutionWidth: 8.56,
	resolutionHeight: 4.82,
	spaceBetweenSlides: 20,
	responsive: {
		small: 1.05,
		medium: 2.15,
		large: 2.15
	}
};

// AVAILABLE SLIDERS CONFIG
export const SLIDERS_CONFIG = {
	normal_cover: DEFAULT_SLIDER_CONFIG,
	normal: DEFAULT_SLIDER_CONFIG,
	large: LARGE_SLIDER_CONFIG,
	schedule: DEFAULT_SLIDER_CONFIG,
	carousel: DEFAULT_SLIDER_CONFIG,
	episodes: DEFAULT_SLIDER_CONFIG,
	packets: LARGE_SLIDER_CONFIG
};

// PRODUCT TYPES
export const PRODUCTS_TYPES = {
	ALL: 'all',
	VOD: 'vod',
	AUDIO: 'audio',
	ARTICLE: 'article',
	LIVE_EVENT: 'live_event',
	SERIES: 'series',
	COLLECTION: 'collection',
	PACKET: 'main',
	SEASON: 'season',
	EPISODE: 'episode',
	PROVISION: 'provision',
	CHANNEL: 'channel',
	BANNER: 'banner',
	PASS: 'pass',
	PROGRAM: 'program'
};

export const PRODUCTS_TYPE_NAMES = {
	ALL: 'all',
	VOD: 'vod',
	AUDIO: 'audio',
	ARTICLE: 'article',
	LIVE_EVENT: 'live_event',
	SERIES: 'series',
	COLLECTION: 'collection',
	PACKET: 'packet',
	SEASON: 'season',
	EPISODE: 'episode',
	PROVISION: 'provision',
	CHANNEL: 'channel',
	BANNER: 'banner',
	PASS: 'pass',
	CHANNEL_EPG: 'channel_epg'
};

export const SLIDER_SLIDE_TYPES = {
	EPISODES: 'episodes',
	CHANNEL_EPG: 'channel_epg'
};

// DATE
export const DATE_FORMAT = {
	DEFAULT: 'dd.mm.yyyy',
	MONTH_YEAR: 'MMM yyyy',
	HOURS_MIN: 'HH:mm',
	MOMENT_DEFAULT: 'YYYY-MM-DD',
	DAY_MONTH_YEAR: 'dd MMMM yyyy',
	DAY_MONTH_YEAR_FORMAT: 'dd.MM.yyyy',
	REGISTER_DATEPICKER_FORMAT: 'dd/MM/yyyy',
	DATE_WITH_TIME: 'dd.MM.yyyy HH:mm',
	LOCALE_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss'
};

// Float widget
export const FLOAT_WIDGET = {
	WRAPPER: 'float-wrapper',
	ELEMENT: 'float-element',
	SPINNER: 'float-spinner',
	ERROR: 'float-error',
	INIT_DATA_TYPE: 'init'
};

//Description line height limit
export const DESCRIPTION_LINE_HEIGHT_LIMIT = {
	LINE_HEIGHT_DESKTOP: 36,
	LINE_HEIGHT_MOBILE: 21
};

// Platform types
export const PLATFORM_TYPES = {
	WEB: 'web'
};

export const FIREBASE_AUTH_METHODS = {
	GOOGLE: 'google.com',
	FACEBOOK: 'facebook.com'
};

// Layout sections id's
export const SECTIONS_ID = {
	NAVIGATION: 'navigation',
	SEARCH: 'search',
	CONTENT: 'content',
	FOOTER: 'footer'
};

export const ARIA_LABEL = 'aria-label';

// DOCUMENTS ALIASES
export const DOCUMENTS_ALIASES = {
	COOKIES_BANNER_ALIAS: 'popup-cookies',
	COOKIES_MODAL_ALIAS: 'cookies-agreement',
	MARKETING_MODAL_ALIAS: 'marketing-agreement',
	ABOUT_US: 'about-us',
	REGULATIONS: 'terms-and-conditions',
	PRIVACY_POLICY: 'privacy-policy',
	COOKIES_POLICY: 'cookies-policy',
	FAQ: 'faq',
	CONTACT_FORM_ALIAS: 'contact-form',
	DCB_PAYMENT_TERMS: 'dcb-payment-terms',
	RIGHT_OF_WITHDRAWAL: 'right-of-withdrawal',
	LEGAL_NOTICE: 'legal-notice'
};

export const ADULT_AGE = 18;

// GOOGLE TASK MANAGER
export const GOOGLE_TASK_MANAGER = {
	PAGE_VIEW: 'pageView'
};

// PERIOD UNITS FOR PAYMENTS (used in user profile tables)
export const PERIOD_UNITS = {
	DAY: 'day',
	HOUR: 'hour',
	MONTH: 'month',
	YEAR: 'year'
};

export const INTL_STYLES = {
	LONG: 'long',
	SHORT: 'short',
	NARROW: 'narrow'
};

export const CATCHUP = 'catchup';

// MEDIA QUERIES BREAKPOINTS
export const MOBILE_DEVICES = '(max-width: 900px)';

// AFFILIATE CLIENT QUERY PARAMETER
export const AFFILIATE_CLIENT = 'partner';

// SUBSCRIPTION TOKEN QUERY PARAMETER
export const SUBSCRIPTION_TOKEN = 'token';

export const EVENTS_NAMES = {
	PLAY: 'play',
	PAUSE: 'pause',
	ERROR: 'error',
	WAITING: 'waiting',
	PLAYING: 'playing',
	LOADEDDATA: 'loadeddata',
	FULLSCREENCHANGE: 'fullscreenchange',
	KEYDOWN: 'keydown',
	ONLINE: 'online'
};

// Innapropriate VOD content types
export const INAPPROPRIATE_VOD_CONTENT_TYPES = {
	VIOLENCE: 'violence',
	SEX: 'sex',
	VULGARISMS: 'vulgarisms',
	DRUGS: 'drugs',
	LACK: 'lack'
};

export const LIMIT_TYPES = {
	FIRST_ACCESS: 'first_access',
	PURCHASE: 'purchase'
};

export const GTM_TRACKING_EVENTS = {
	REGISTRATION_SUCCESSFUL: 'registration_successful'
};
